import React, { useRef } from 'react'

import TaskItemTags from './TaskItemTags'
import Tags from './TagsArea/Tags'

export default function TaskItemTagsContainer({
    task,
    isSubtask,
    projectId,
    isObservedTask,
    isToReviewTask,
    toggleSubTaskList,
    subtaskList,
    isActiveOrganizeMode,
    accessGranted,
    anonymousGranted,
    forceTagsMobile,
    setTagsExpandedHeight,
    isLocked,
    isSuggested,
    isPending,
}) {
    const amountTagsRef = useRef(0)

    return (
        <TaskItemTags
            amountTags={amountTagsRef.current}
            isSubtask={isSubtask}
            setTagsExpandedHeight={value => setTagsExpandedHeight(value)}
        >
            <Tags
                amountTagsRef={amountTagsRef}
                task={task}
                isSubtask={isSubtask}
                projectId={projectId}
                isObservedTask={isObservedTask}
                isToReviewTask={isToReviewTask}
                toggleSubTaskList={toggleSubTaskList}
                subtaskList={subtaskList}
                isActiveOrganizeMode={isActiveOrganizeMode}
                accessGranted={accessGranted}
                anonymousGranted={anonymousGranted}
                forceTagsMobile={forceTagsMobile}
                isLocked={isLocked}
                isSuggested={isSuggested}
                isPending={isPending}
            />
        </TaskItemTags>
    )
}
